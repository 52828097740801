export default async function qcount(){
    let c =  await getqcount();
    const ans = c === 'null' ? JSON.stringify(0) : c
    return await setcount(ans)
}

export async function getqcount(){
    return localStorage.getItem("qcount"); 
}

export async function initq(){
    const c = await getqcount()
    const ans = c === null || c ==='null' ? JSON.stringify(0) : c
    localStorage.setItem("qcount", ans)
    return ans
}

export async function setcount(count){
    const ans = (parseInt(count) +1)
    localStorage.setItem("qcount", JSON.stringify(ans))
    return ans

}